import { v4 as uuid } from 'uuid';

export const getChatThreadId = () => {
  let chatThreadId = localStorage.getItem('chatThreadId');

  if (!chatThreadId) {
    chatThreadId = uuid();
    localStorage.setItem('chatThreadId', chatThreadId);
  }
  return chatThreadId;
};

import { useState } from 'react';

import {
  Card,
  CardContent,
  Typography,
  Link,
  Box,
  CardHeader,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { CONTACT_EMAIL_ADDRESS, INDEXED_SITES } from '../constants';
import { styles } from '../styles/stylesheet';

const ExtensionCard = () => {
  const [isDismissed, setIsDismissed] = useState(() => {
    return sessionStorage.getItem('chaas-is-dismissed') === 'true';
  });

  const handleDismiss = () => {
    sessionStorage.setItem('chaas-is-dismissed', 'true');
    setIsDismissed(true);
  };

  return (
    <Card
      sx={styles.extensionCard}
      style={{ display: isDismissed ? 'none' : 'block' }}
    >
      <CardHeader
        action={
          <IconButton onClick={handleDismiss}>
            <CloseIcon color='disabled' />
          </IconButton>
        }
        title={
          <Typography variant='subtitle1' gutterBottom>
            To see how Chaas works, use our extension and visit these pages:
          </Typography>
        }
      />
      <CardContent>
        <Box component='ul' sx={{ pl: 2, mb: 2 }}>
          {INDEXED_SITES.map((site) => (
            <li key={site.url}>
              <Link
                href={site.url}
                underline='hover'
                target='_blank'
                rel='noopener'
              >
                {site.name}
              </Link>
            </li>
          ))}
        </Box>

        <Typography variant='body2'>
          To download the extension:{' '}
          <Link href={`mailto:${CONTACT_EMAIL_ADDRESS}`} underline='hover'>
            Get in touch
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ExtensionCard;

import { Link as RouterLink } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Grid,
  Card,
  Typography,
  Chip,
  Button,
  Link,
  CardHeader,
  CircularProgress,
  CardActions,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { useTheme } from '@mui/material/styles';

import { styles } from '../styles/stylesheet';
import { SiteData, Status } from '../types';
import { useApiClient } from '../context/ApiClientContext';

const SITE_POLL_INTERVAL = 5000; // ms

const SiteCard = ({
  site,
  handleEmbedCodeOpen,
}: {
  site: SiteData;
  handleEmbedCodeOpen: (siteId: string) => void;
}) => {
  const [status, setStatus] = useState<Status>(site.status);
  const { apiClient } = useApiClient();

  const theme = useTheme();

  const fetchSiteStatus = useCallback(async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${site.id}/status`,
      );
      const statusResponse = response.data.status;
      setStatus(statusResponse);
    } catch (error) {
      console.error('Error fetching site status:', error);
    }
  }, [site.id, apiClient]);

  useEffect(() => {
    if (status === 'ready') {
      return;
    }

    fetchSiteStatus();

    const timerId = setInterval(() => {
      fetchSiteStatus();
    }, SITE_POLL_INTERVAL);

    return () => clearInterval(timerId);
  }, [fetchSiteStatus, status]);

  return (
    <Grid item xs={12} sm={6} md={4} key={site.id}>
      <Card sx={styles.siteCard}>
        <CardHeader
          title={
            <Link
              href={site.url}
              underline='always'
              target='_blank'
              rel='noopener'
            >
              <Typography variant='subtitle1' color='textPrimary'>
                {site.url}
              </Typography>
            </Link>
          }
        />
        <CardActions disableSpacing sx={styles.siteCardActions}>
          <Button
            sx={{ marginRight: 1 }}
            component={RouterLink}
            to={`/dashboard/sites/${site.id}`}
            size='small'
            variant='contained'
          >
            INFO
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleEmbedCodeOpen(site.id);
            }}
            size='small'
            variant='contained'
          >
            EMBED
          </Button>
          <Box sx={styles.chipBox}>
            <Chip
              label={
                ['pending', 'indexing', 'scraped', 'scraping'].includes(
                  status,
                ) ? (
                  <>
                    <CircularProgress
                      size={11}
                      color='inherit'
                      sx={{ marginRight: 1, marginTop: 0.5 }}
                    />
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </>
                ) : (
                  'Indexed'
                )
              }
              variant='outlined'
              sx={{
                marginRight: 1,
                color: ['pending', 'indexing', 'scraped', 'scraping'].includes(
                  status,
                )
                  ? 'grey'
                  : theme.palette.primary.main,
              }}
            />
            <Chip
              label={`${site.pages.length}`}
              color='primary'
              variant='outlined'
              icon={<DescriptionIcon />}
            />
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default SiteCard;

import { useCallback, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { AppProvider } from '@toolpad/core';

import { useSession } from '../context/SessionContext';

export default function DasboardLayout() {
  const { supabase, session, isUserAuthenticated, handleLogout } = useSession();

  const navigate = useNavigate();

  const signIn = useCallback(() => {
    navigate('/auth');
  }, [navigate]);

  const signOut = useCallback(async () => {
    await supabase.auth.signOut({ scope: 'local' });
    handleLogout();

    navigate('/auth');
  }, [navigate, supabase, handleLogout]);

  // Reference: https://mui.com/toolpad/core/integrations/react-router/#protect-routes-inside-the-dashboard-layout
  useEffect(() => {
    if (!session) {
      navigate('/auth');
    }
  }, [session, navigate]);

  return (
    <AppProvider
      branding={{
        logo: <img src='/pudina-chaas.png' alt='Chaas logo' />,
        title: 'Chaas',
      }}
      session={session}
      authentication={{ signIn, signOut }}
    >
      <DashboardLayout hideNavigation>
        {/* TODO: Width for container and header */}
        {/* <PageContainer> */}
        {isUserAuthenticated && <Outlet />}
        {/* </PageContainer> */}
      </DashboardLayout>
    </AppProvider>
  );
}

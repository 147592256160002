import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';

import { styles } from '../styles/stylesheet';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
};

export default function DeleteSiteDialog({
  open,
  handleClose,
  handleDelete,
}: Props) {
  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogContent>Are you sure you want to delete the site?</DialogContent>
      <DialogActions sx={styles.codeBlockActions}>
        <Button
          onClick={handleDelete}
          color='primary'
          variant='contained'
          size='small'
        >
          Delete
        </Button>
        <Button onClick={handleClose} size='small'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { NotificationsProvider } from '@toolpad/core/useNotifications';

import WelcomePage from './pages/WelcomePage';
import DashboardLayout from './layouts/DashboardLayout';
import Site from './pages/dashboard/sites/Id';
import DashboardHome from './pages/dashboard/DashboardHome';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AuthPage from './pages/AuthPage';
import { SessionProvider } from './context/SessionContext';
import { ApiClientProvider } from './context/ApiClientContext';
import Chatbot from './pages/Chat';

const router = createBrowserRouter([
  {
    path: '/',
    Component: WelcomePage,
  },
  {
    path: '/dashboard',
    Component: DashboardLayout,
    children: [
      {
        path: '',
        Component: DashboardHome,
      },
      {
        path: 'sites/:id',
        Component: Site,
      },
    ],
  },
  {
    path: '/auth',
    Component: AuthPage,
  },
  {
    path: '/chat',
    Component: Chatbot,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <NotificationsProvider>
      <ApiClientProvider>
        <SessionProvider>
          <RouterProvider router={router} />
        </SessionProvider>
      </ApiClientProvider>
    </NotificationsProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

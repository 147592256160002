import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// Ref: Styles provided - https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_STYLES_HLJS.MD
import { vs2015 } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Snackbar,
} from '@mui/material';

import { styles } from '../styles/stylesheet';

type Props = {
  code: string;
  language: string;
  open: boolean;
  handleClose: () => void;
};

export default function CodeBlock({
  code,
  language,
  open,
  handleClose,
}: Props) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopy = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Dialog open={open} maxWidth='md' fullWidth>
        <DialogTitle>Embed Code</DialogTitle>
        <DialogContent>
          <div style={{ position: 'relative', width: '100%' }}>
            <CopyToClipboard text={code} onCopy={handleCopy}>
              <Button sx={styles.copyButton}>
                <ContentCopyIcon />
              </Button>
            </CopyToClipboard>
            <SyntaxHighlighter
              language={language}
              style={vs2015}
              wrapLines={false}
              wrapLongLines={false}
              customStyle={{
                border: '1px solid #c3c3c3',
                borderRadius: '5px',
              }}
            >
              {code}
            </SyntaxHighlighter>
          </div>
        </DialogContent>
        <DialogActions sx={styles.codeBlockActions}>
          <Button
            onClick={handleClose}
            color='primary'
            variant='contained'
            size='small'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message='Code copied to clipboard'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  );
}

export const styles = {
  urlForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    marginTop: 3,
  },
  extensionCard: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    width: 320,
    borderRadius: 2,
    boxShadow: 4,
    zIndex: 999,
  },
  siteCard: {
    borderRadius: 2,
    boxShadow: 3,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  chipBox: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  siteCardActions: {
    padding: 2,
    marginTop: 'auto',
  },
  addSiteFAB: {
    position: 'fixed',
    top: 74,
    right: 16,
    color: 'black',
  },
  dashboardTitle: {
    flexGrow: 1,
    pr: 6,
    mt: 6,
  },
  sitesCard: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
  },
  deleteSiteButton: {
    margin: 2,
    marginLeft: 0,
  },
  copyButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    border: 'none',
    cursor: 'pointer',
    background: 'none',
  },
  codeBlockActions: {
    pr: 3,
    pb: 3,
  },
  divider: {
    marginRight: 3,
    marginLeft: 3,
  },
  wrapper: {
    position: 'fixed',
    bottom: '0px',
    right: '0px',
  },
  chatButton: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '9px',
    cursor: 'pointer',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    textTransform: 'none',
    width: '168px',
  },
  // TODO: Replace dimensions with calc values
  chatContainer: {
    position: 'fixed',
    bottom: '1px',
    right: '0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    overflow: 'hidden',
    backgroundColor: '#808080',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },

  chatContainerDetails: {
    bottom: '1px',
    right: '0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    overflow: 'hidden',
    backgroundColor: '#808080',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },

  chatContainerFullScreen: {
    position: 'fixed',
    bottom: '1vh',
    right: '1vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '96vw',
    height: '97vh',
    borderRadius: '10px',
    overflow: 'hidden',
    backgroundColor: '#808080',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  messagesArea: {
    flex: 1,
    padding: 2,
    overflowY: 'auto',
    backgroundColor: '#f5f5f5',
  },
  inputArea: {
    display: 'flex',
    alignItems: 'center',
    padding: 1,
    borderRadius: 0,
    borderTop: '1px solid #ccc',
    backgroundColor: 'white',
  },
  input: {
    flex: 1,
    padding: 2,
    borderRadius: 1.5,
    border: '1px solid #ccc',
    backgroundColor: '#f9f9f9',
    margin: 1,
    lineHeight: '1.15em',
  },
  display: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  loadingText: {
    marginLeft: 2,
  },
  loaderIcon: {
    marginTop: '3px',
  },
  header: {
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 2,
    backgroundColor: 'black',
  },
  image: {
    height: '60%',
    width: 'auto',
    marginLeft: 3,
  },
  messageCard: {
    padding: 10,
    borderRadius: 4,
  },
  chatbotTitle: {
    flexGrow: 1,
  },
  ellipsisContainer: {
    backgroundColor: '#ededed',
    minWidth: '15px',
    padding: '10px',
    borderRadius: '4px',
  },
};

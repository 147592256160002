import React, { useState } from 'react';
import axios from 'axios';

import IconButton from '@mui/material/IconButton';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import { useTheme } from '@mui/material';

import { Message } from '../types';

interface FeedbackProps {
  message: Message;
}

const Feedback: React.FC<FeedbackProps> = ({ message }) => {
  const theme = useTheme();

  const [feedback, setFeedback] = useState({
    upvoted: message.feedback === 'U',
    downvoted: message.feedback === 'D',
  });

  const handleFeedback = async (type: 'U' | 'D') => {
    await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/messages/${message.messageId}`,
      { feedback: type },
      { headers: { 'Content-Type': 'application/json' } },
    );

    setFeedback({
      upvoted: type === 'U',
      downvoted: type === 'D',
    });
  };

  return (
    <div>
      <IconButton onClick={() => handleFeedback('U')}>
        <ThumbUpRoundedIcon
          sx={{
            color: feedback.upvoted
              ? theme.palette.common.black
              : theme.palette.grey[400],
          }}
        />
      </IconButton>
      <IconButton onClick={() => handleFeedback('D')}>
        <ThumbDownRoundedIcon
          sx={{
            color: feedback.downvoted
              ? theme.palette.common.black
              : theme.palette.grey[400],
          }}
        />
      </IconButton>
    </div>
  );
};

export default Feedback;

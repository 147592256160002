import { useCallback, useEffect, useState } from 'react';

import { Box, Grid, Typography, Fab, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { SiteData } from '../../types';
import CodeBlock from '../../components/CodeBlockDialog';
import ExtensionCard from '../../components/ExtensionCard';
import URLForm from '../../components/URLForm';
import { useApiClient } from '../../context/ApiClientContext';
import SiteCard from '../../components/SiteCard';
import { styles } from '../../styles/stylesheet';

const scriptTemplate = `<script src="${process.env.REACT_APP_CHATBOT_SCRIPT_URL}"></script>\n<!-- Add above code just before the closing \`</body>\` tag -->`;

const DashboardHome = () => {
  const [embedDialogOpen, setEmbedDialogOpen] = useState<boolean>(false);
  const [addSiteDialogOpen, setAddSiteDialogOpen] = useState<boolean>(false);
  const [sites, setSites] = useState<SiteData[]>();

  const { apiClient } = useApiClient();

  const handleEmbedCodeOpen = useCallback(() => {
    setEmbedDialogOpen(true);
  }, []);

  const handleEmbedCodeClose = useCallback(() => {
    setEmbedDialogOpen(false);
  }, []);

  const fetchSites = useCallback(async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/sites`,
      );
      const sites = response.data.sites;

      setSites(sites);
    } catch (error) {
      console.error('Error fetching sites data:', error);
    }
  }, [apiClient]);

  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  return sites ? (
    <>
      {sites.length > 0 ? (
        <Box sx={styles.dashboardTitle}>
          <Typography marginLeft='24px' variant='h5'>
            My sites
          </Typography>
          <Grid container spacing={3} sx={{ alignItems: 'stretch' }}>
            {sites.map((site) => (
              <SiteCard
                key={site.id}
                site={site}
                handleEmbedCodeOpen={handleEmbedCodeOpen}
              />
            ))}
          </Grid>
        </Box>
      ) : (
        <Box sx={styles.sitesCard}>
          <Typography variant='h6'>
            No sites indexed yet. Add a site to get started!
          </Typography>
        </Box>
      )}
      <Fab
        onClick={(e) => {
          e.stopPropagation();
          setAddSiteDialogOpen(true);
        }}
        sx={styles.addSiteFAB}
        aria-label='add site'
        variant='extended'
      >
        <AddIcon sx={{ marginRight: 1 }} />
        <Typography variant='button'>Add site</Typography>
      </Fab>
      <ExtensionCard />
      <CodeBlock
        code={scriptTemplate}
        language={'html'}
        open={embedDialogOpen}
        handleClose={handleEmbedCodeClose}
      />
      <URLForm
        open={addSiteDialogOpen}
        handleClose={() => setAddSiteDialogOpen(false)}
        fetchSites={fetchSites}
      />
    </>
  ) : (
    <Box display='flex' justifyContent='center' width='100%' sx={{ mt: 40 }}>
      <CircularProgress sx={{ alignSelf: 'center', justifySelf: 'center' }} />
    </Box>
  );
};

export default DashboardHome;

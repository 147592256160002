import React, { useState } from 'react';

import {
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from '@mui/material';
import { useNotifications } from '@toolpad/core/useNotifications';

import { styles } from '../styles/stylesheet';
import { useApiClient } from '../context/ApiClientContext';

const LLM_PROVIDERS = [
  {
    label: 'Mistral',
    value: 'mistral',
  },
  {
    label: 'OpenAI',
    value: 'openai',
  },
];

interface Props {
  open: boolean;
  handleClose: () => void;
  fetchSites: () => Promise<void>;
}

function URLForm({ open, handleClose, fetchSites }: Props) {
  const [url, setUrl] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [llmProvider, setLlmProvider] = useState(LLM_PROVIDERS[0].value);

  const { apiClient } = useApiClient();
  const notifications = useNotifications();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // TODO: Integrate form library
    if (apiKey === '') {
      notifications.show('API Key cannot be empty', {
        severity: 'error',
        autoHideDuration: 3000,
      });

      return;
    }

    if (llmProvider === '') {
      notifications.show('Please select an LLM Provider', {
        severity: 'error',
        autoHideDuration: 3000,
      });

      return;
    }

    if (isValidUrl(url)) {
      try {
        await apiClient.post(
          `${process.env.REACT_APP_BACKEND_URL}/sites/index`,
          {
            url,
            llmKey: apiKey,
            isPublic,
            llmProvider,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        notifications.show('Scraping initiated!', {
          severity: 'success',
          autoHideDuration: 3000,
        });
        handleClose();
        fetchSites();
      } catch (error) {
        notifications.show('An error occurred while making the request.', {
          severity: 'error',
          autoHideDuration: 3000,
        });
        console.error(error);
      }
    } else {
      notifications.show('Please enter a valid URL.', {
        severity: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const isValidUrl = (urlString: string) => {
    try {
      new URL(urlString);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <>
      <Dialog open={open} maxWidth='sm' fullWidth>
        <DialogTitle>Enter following details to index the website</DialogTitle>
        <DialogContent>
          <Box component='form' onSubmit={handleSubmit} sx={styles.urlForm}>
            <TextField
              label='URL'
              variant='outlined'
              fullWidth
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              autoComplete='off'
            />
            <TextField
              select
              value={llmProvider}
              variant='outlined'
              label='Model'
              onChange={(e) => setLlmProvider(e.target.value)}
            >
              {LLM_PROVIDERS.map((provider) => (
                <MenuItem key={provider.value} value={provider.value}>
                  {provider.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label='API Key'
              variant='outlined'
              fullWidth
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              autoComplete='off'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPublic}
                  onChange={() => setIsPublic(!isPublic)}
                  name='isPublic'
                  color='primary'
                />
              }
              label='Public'
            />
            <DialogActions>
              <Button type='submit' variant='contained' color='primary'>
                Submit
              </Button>
              <Button onClick={handleClose} color='primary' variant='contained'>
                Cancel
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default URLForm;

import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import {
  Box,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Link,
  Button,
} from '@mui/material';
import { useNotifications } from '@toolpad/core';
import DeleteIcon from '@mui/icons-material/Delete';

import DeleteSite from '../../../components/DeleteSiteDialog';
import { Message, PageData, SiteData } from '../../../types';
import { useApiClient } from '../../../context/ApiClientContext';
import { useSession } from '../../../context/SessionContext';
import { styles } from '../../../styles/stylesheet';
import ChatContainer from '../../../components/ChatContainer';

function Site() {
  const [messages, setMessages] = useState<Message[]>([]);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [site, setSite] = useState<SiteData>();
  const [pages, setPages] = useState<PageData[]>();
  const [pagesCount, setPagesCount] = useState<number>();
  const [threadId, setThreadId] = useState<string>('');

  const navigate = useNavigate();
  const { id } = useParams();
  const notifications = useNotifications();
  const { apiClient } = useApiClient();
  const { userId } = useSession();

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      const response = await apiClient.delete(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${id}`,
      );

      const isSiteDeleted = response.data.isSiteDeleted;

      if (isSiteDeleted) {
        notifications.show('Site Deleted!', {
          severity: 'success',
          autoHideDuration: 3000,
        });
        navigate('/dashboard');
      } else {
        notifications.show(
          'An error occurred while deleting the site. Please try again.',
          {
            severity: 'error',
            autoHideDuration: 3000,
          },
        );
      }
    } catch (error) {
      notifications.show('An error occurred while making the request.', {
        severity: 'error',
        autoHideDuration: 3000,
      });
      console.error(error);
    }
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    const fetchSite = async () => {
      try {
        const siteResponse = await apiClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/sites/${id}`,
        );

        const site = siteResponse.data.site;
        const pagesCount = siteResponse.data.pagesCount;
        setSite(site);
        setPagesCount(pagesCount);

        const pagesResponse = await apiClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/sites/${id}/pages`,
        );

        const pages = pagesResponse.data.pages;
        setPages(pages);
      } catch (error) {
        console.error('Error fetching site data:', error);
      }
    };

    fetchSite();
  }, [id, apiClient]);

  const handleReIndexing = async () => {
    try {
      await apiClient.post(
        `${process.env.REACT_APP_BACKEND_URL}/sites/re-index/${site?.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      notifications.show('Scraping initiated!', {
        severity: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      notifications.show('An error occurred. Please try again.', {
        severity: 'error',
        autoHideDuration: 3000,
      });
      console.error(error);
    }
  };

  useEffect(() => {
    if (!site || site.status !== 'ready') return;

    const chatThreadId = `${userId}_${site.id}`;

    setThreadId(chatThreadId);

    const fetchMessages = async () => {
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/messages?threadId=${chatThreadId}`,
        );
        const messages = response.data?.messages?.reverse() || [];

        setMessages(messages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [userId, site, apiClient]);

  return site ? (
    <>
      <Box sx={{ pr: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  Details
                </Typography>
                <Card>
                  <CardContent>
                    <List>
                      <ListItem>
                        <ListItemText primary='URL' secondary={site.url} />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary='Status'
                          secondary={
                            [
                              'pending',
                              'indexing',
                              'scraping',
                              'scraped',
                            ].includes(site.status)
                              ? site.status.toUpperCase()
                              : 'INDEXED'
                          }
                        />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary='Depth'
                          secondary={site.depth || 'N/A'}
                        />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary='Page Count'
                          secondary={pagesCount}
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  Pages
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Page URL</TableCell>
                        <TableCell align='right'>Depth</TableCell>
                        <TableCell>Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pages && pages.length > 0 ? (
                        pages.map((page: any) => (
                          <TableRow key={page.id}>
                            <TableCell>
                              <Link
                                underline='hover'
                                target='_blank'
                                rel='noopener'
                                href={page.url}
                              >
                                {page.url}
                              </Link>
                            </TableCell>
                            <TableCell align='right'>{page.depth}</TableCell>
                            <TableCell>
                              {DateTime.fromISO(page.createdAt).toRelative()}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>No pages available</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item>
                    <Button
                      variant='contained'
                      onClick={handleReIndexing}
                      disabled={site.status === 'pending'}
                    >
                      Re-index site
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      startIcon={<DeleteIcon />}
                      sx={styles.deleteSiteButton}
                      onClick={handleOpenDeleteDialog}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} height={'800px'}>
            <Box sx={{ height: '100%', width: '95%' }}>
              <ChatContainer
                siteId={site.id}
                status={site.status}
                messages={messages}
                setMessages={setMessages}
                isIframe={false}
                threadId={threadId}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <DeleteSite
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleDelete={handleDelete}
      />
    </>
  ) : null;
}

export default Site;

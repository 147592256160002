import { createContext, useContext } from 'react';
import axios, { AxiosInstance } from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

export interface ApiClientContextValue {
  apiClient: AxiosInstance;
}

export const ApiClientContext = createContext<ApiClientContextValue>({
  apiClient: apiClient,
});

export const ApiClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ApiClientContext.Provider value={{ apiClient }}>
      {children}
    </ApiClientContext.Provider>
  );
};

// Custom hook to use the ApiClientContext
export const useApiClient = () => {
  const context = useContext(ApiClientContext);
  if (!context) {
    throw new Error('useApiClient must be used within a ApiClientProvider');
  }
  return context;
};

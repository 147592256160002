import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { Box } from '@mui/material';

import { useSession } from '../context/SessionContext';

const AuthPage = () => {
  const { supabase, session } = useSession();

  const navigate = useNavigate();

  useEffect(() => {
    if (session && session.access_token) {
      navigate('/dashboard', { replace: true });
    }
  }, [session, navigate]);

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{ mt: 10 }}
    >
      <Auth
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa }}
        view='sign_in'
        providers={[]}
      />
    </Box>
  );
};

export default AuthPage;
